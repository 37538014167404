@import 'assets/scss/variable.module.scss';

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: transparent;
  border-width: 1px;
  border-color: rgba(var(--main-bg-color-rgb), 0.25);
  font-size: 14px;
  @media (min-width: 1024px) {
    border-width: 1px;
    height: 46px;
    font-size: 18px;
    border-radius: 12px;
  }
  @media (min-width: 1536px) {
    border-width: 1px;
    height: 53px;
    font-size: 18px;
    border-radius: 12px;
  }
  border-radius: 8px;
  font-family: 'Muli-SemiBold';
  font-weight: 100;
  letter-spacing: 0;
  color: var(--main-bg-color);
  filter: drop-shadow(0px 3px 6px rgba(var(--brand-01-color), 0.16));
}
