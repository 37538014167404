@import 'assets/scss/variable.module.scss';

.container {
  position: relative;
  z-index: 10002;
}

.bgView {
  background-color: rgba(#1a1a1a, 0.8);
  position: fixed;
  inset: 0;
}

.modalView {
  position: absolute;
  left: 1px;
  right: 1px;
  bottom: -100%;
  width: auto;
  height: auto;
  @media (min-width: 1024px) {
    width: 375px;
    height: 255px;
    border-radius: 20px;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
  }
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #1a1a1a;
  border: 1px solid rgba(#eff0f6, 0.1);
  transition: bottom 0.5s ease;
}

.hide {
  bottom: -100%;
  @media (min-width: 1024px) {
    bottom: auto;
  }
}

.show {
  bottom: 0px;
  @media (min-width: 1024px) {
    bottom: auto;
  }
}

.separator {
  margin-top: 20px;
  margin-bottom: 18px;
  width: 100%;
  height: 1px;
  background: rgba(#eff0f6, 0.1)
}

.share {
  font-family: 'Muli-Bold';
  font-size: 18px;
  line-height: 23px;
  color: $text-gray-200-color;
}

.shareArtwork {
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 20px;
  color: rgba($text-gray-200-color, 0.75);
  margin-bottom: 30px;
}

.menuItem {
  width: 100%;
  justify-items: center;
  font-family: 'Muli-Regular';
  font-size: 14px;
  line-height: 18px;
  color: $text-gray-200-color;
  margin-bottom: 10px;
}

.clipboard {
  display: flex;
  align-items: center;
  gap: 9px;
  justify-items: center;
  font-family: 'Muli-Regular';
  font-size: 16px;
  color: $text-gray-200-color;
  cursor: pointer;
}

.cancel {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  line-height: 20px;
  color: $text-gray-200-color;
  margin-top: 20px;
  margin-bottom: 20px;

  text-align: center;
}
