@import 'assets/scss/variable.module.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  // min-height: 564px;
  height: 100%;
  // background: #020303;
  background-color: #1A1A1A;
}

.border {
  width: 100%;
  height: 2px;
  background: rgba(var(--main-bg-color-rgb), 0.1);
}

.topLeftEllipse {
  position: absolute;
  top: 152px;
  right: -220px;
  z-index: 0;
  opacity: 0.25;
}

.title {
  font-family: 'Muli-Regular';
  font-size: 18px;
  line-height: 36px;
  letter-spacing: 0;
  color: #eff0f6;
  @media (max-width: 576px) {
    font-size: 18px;
  }
}

.lightTitle {
  color: var(--background-dark-color);
}

.stayTitle {
  font-family: 'Muli-Bold';
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0;
  color: #eff0f6;
  @media (max-width: 576px) {
    font-size: 25px;
  }
}

.text {
  margin-top: 39px;
  font-family: 'Muli-Bold';
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
}

.linkText {
  cursor: pointer;
  margin-top: 16px;
  font-family: 'Muli-Regular';
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.8;
  color: #eff0f6;
  &:hover {
    @media (min-width: 1024px) {
      opacity: 0.6;
    }
  }
  @media (max-width: 576px) {
    font-size: 12px;
    margin-top: 14px;
  }
}

.lightLinkText {
  color: var(--background-dark-color);
}

.greenText {
  width: 100%;
  margin-top: 62px;
  font-size: 14px;
  @media (min-width: 1024px) {
    margin-top: 40px;
    font-size: 14px;
  }
  display: flex;
  justify-content: center;
  font-family: 'Muli-Light';
  line-height: 24px;
  letter-spacing: 0;
  color: #eff0f6;
  opacity: 0.6;
}

.lightDarkMode {
  margin-top: 70px;
  @media (min-width: 1024px) {
    margin-top: 60px;
  }
}

.logoFont {
  font-family: "Ibrand" !important;
  font-size: 37px !important;
  margin-top: 6px;
  color: #eff0f6
}

.logoIcon {
  scale: 1;
  overflow: visible;
  fill: #95dbd5;
}

.iconColor {
  fill: #95dbd5 !important;
}

.socialBtn {
  width: 46px;
  height: 46px;
  border-radius: 12px;
  background: rgba(#eff0f6, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
}