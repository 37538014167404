@import 'assets/scss/variable.module.scss';

.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin-top: 16px;
  @media (min-width: 768px) {
    margin-top: 0px;
  }
  z-index: 10;
}

.bannerBG {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-new-color)
}

.bannerImage {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;
}

.headerImage {
  min-height: 132px;
  position: relative;
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -16px;
  @media (min-width: 1024px) {
    width: 100%;
    margin: 0px;
  }
}

.iconContainer {
  height: 45px;
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
}

.bioText {
  font-family: 'Muli-Light';
  font-size: 16px;
  line-height: 20px;
  color: var(--text-gray-200-color);
}

.shareText {
  font-family: 'Muli-SemiBold';
  font-size: 14px;
  font-weight: 100;
  line-height: 18px;
  color: var(--text-gray-200-color);
}

.refereshText {
  font-family: 'Muli-Regular';
  font-size: 14px;
  line-height: 18px;
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
  }
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
}

.iconBlurView {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: var(--background-main-color);
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
}

.skeletonLoader:empty {
  display: block;
  background-color: var(--main-bg-color) !important;
  border-color: var(--main-bg-color) !important;
  animation-name: skeletonAnimation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &.others {
    height: 453px;
  }

  &.prod-name {
    height: 20px;
  }

  &.prod-description {
    width: 80%;
    height: calc(100% - 30px);
  }
}

@keyframes skeletonAnimation {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.05;
  }
  100% {
    opacity: 0.2;
  }
}

.bannerBoard {
  position: absolute;
  bottom: 17px;
  right: 140px;
  background: var(--bg-242424-EEEDEB);
  box-shadow: 0px 0px 10px rgba(#FFF, 0.36);
  width: calc(100% - 48px);
  height: 136px;
  left: 24px;
  right: 28px;
  top: 16px;
  padding: 12px 13px;
  border-radius: 10px;
  @media (min-width: 1024px) {
    width: 480px;
    height: 136px;
    padding: 16px 21px;
    bottom: 17px;
    left: auto;
    top: auto;
    right: 140px;
  }
  z-index: 10001;
}

.boardTitle {
  font-family: 'Muli-Bold';
  font-size: 18px;
  font-weight: 100;
  color: var(--text-gray-200-color);
}

.boardText {
  font-family: 'Muli-Regular';
  font-size: 14px;
  font-weight: 100;
  color: var(--text-gray-200-color);
  margin-top: 4px;
}
