@import 'assets/scss/variable.module.scss';

.container {
  width: 100%;
  height: 50px;
  background: var(--search-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 12px;
}

.inputArea {
  flex: 1;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 24px;
  color: var(--text-gray-200-color);
  background-color: transparent;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  @media (min-width: 1024px) {
    background-color: transparent;
    border-radius: 12px;
  }
  outline: none;
}

.inputArea::placeholder {
  color: rgba(var(--text-gray-200-color-rgb), 0.5);
}

.iconArea {
  height: 40px;
  padding-left: 15px;
  padding-right: 6px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
