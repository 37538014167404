@import 'assets/scss/variable.module.scss';

.container {
  width: 100%;
  position: relative;
  padding-bottom: 100px;
}

.topLeftEllipse {
  position: absolute;
  top: 200px;
  left: -860px;
  z-index: 0;
  opacity: 0.25;
}

.topRightEllipse {
  width: 1705px;
  height: 1705px;
  position: absolute;
  top: 400px;
  right: -440px;
  z-index: 0;
  opacity: 0.25;
}

.bottomLeftEllipse {
  position: absolute;
  top: -100vh;
  left: -860px;
  z-index: 0;
  opacity: 0.25;
}

.fullScreen {
  position: absolute;
  left: 0px;
  top: -100px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: calc(100vh + 20px);
  background: rgba(var(--background-light-color-rgb), 0.15);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-top: 43px;
  // padding-bottom: 43px;
}

.closeScreen {
  position: absolute;
  right: 20px;
  top: 43px;
  width: 40px;
  height: 40px;
  @media (min-width: 1024px) {
    right: 153px;
    top: 43px;
    width: 52px;
    height: 52px;
  }
  border-radius: 15px;
  background: rgba(#545250, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10001;
}
