@import 'assets/scss/variable.module.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media (min-width: 1024px) {
  }
  align-items: center;
}

.avatarArea {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  @media (min-width: 1024px) {
    width: 164px;
    height: 164px;
  }
  border-radius: 100%;
  background: var(--bg-new-color);
  z-index: 1;
}

.profileBG {
  position: absolute;
  inset: -1px -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 100%;
  background-color: var(--bg-new-color)
}

.name {
  display: flex;
  width: 100%;
  text-align: start;
  font-family: 'Muli-ExtraBold';
  font-size: 26px;
  line-height: 29px;
  margin-top: 18px;
  @media (min-width: 1024px) {
    font-family: 'Muli-Bold';
    font-size: 30px;
    line-height: 36px;
    margin-top: 12px;
  }
  letter-spacing: 0px;
  color: var(--text-gray-200-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bottomSection {
  width: 100%;
  min-width: 240px;
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.iconProfileBlurView {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: var(--background-main-color);
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
}

.skeletonLoader:empty {
  display: block;
  background-color: var(--main-bg-color) !important;
  border-color: var(--main-bg-color) !important;
  animation-name: skeletonAnimation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &.others {
    height: 453px;
  }

  &.prod-name {
    height: 20px;
  }

  &.prod-description {
    width: 80%;
    height: calc(100% - 30px);
  }
}

@keyframes skeletonAnimation {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.05;
  }
  100% {
    opacity: 0.2;
  }
}
