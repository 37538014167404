@import 'assets/scss/variable.module.scss';

.container {
  position: relative;
  z-index: 10002;
}

.bgView {
  position: fixed;
  inset: 0;
}

.headerView {
  width: 100%;
  height: 80px;
  background: var(--background-main-color);
  border-bottom: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}

.title {
  font-family: 'Muli-SemiBold';
  font-size: 18px;
  line-height: 24px;
  color: var(--text-gray-200-color);
}

.modalView {
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--bg-new-color);  
  @media (min-width: 1024px) {
    padding: 43px 32px 39px 32px;
  }
}

.close {
  position: absolute;
  top: 20px;
  right: 24px;
  @media (min-width: 1024px) {
    right: 16px;
  }
  cursor: pointer;
  z-index: 20;
}

.avatarArea {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  @media (min-width: 1024px) {
    width: 52px;
    height: 52px;
  }
  border-radius: 100%;
  // background: linear-gradient(
  //   110deg,
  //   var(--brand-06-color) -60%,
  //   #5e2028 20%,
  //   var(--brand-01-color) 50%,
  //   var(--brand-04-color) 80%,
  //   var(--brand-01-color) 140%
  // );
}

.username {
  font-family: 'Muli-Bold';
  font-size: 14px;
  line-height: 18px;
  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 24px;
  }
  color: var(--text-gray-200-color);
  text-align: start;
  cursor: pointer;
}

.text {
  font-family: 'Muli-Regular';
  font-size: 12px;
  line-height: 15px;
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
  }
  color: var(--text-gray-200-color);
}

.searchBox {
  width: 100%;
  margin-top: 18px;
  padding-left: 20px;
  padding-right: 20px;
}
