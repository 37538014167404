@import 'assets/scss/variable.module.scss';

.scrollbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.mobileScrollbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 10;
  overflow-x: auto;
}

.container {
  width: auto;
  height: 35px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Muli-SemiBold';
  color: var(--text-gray-200-color);
  border-bottom: 3px solid transparent;
  &:focus {
    outline: none;
  }
  font-size: 16px;
  // padding-left: 14px;
  // padding-right: 14px;
  margin-left: 12px;
  margin-right: 12px;
  @media (min-width: 640px) {
    font-size: 16px;
    // padding-left: 14px;
    // padding-right: 14px;
  }
  @media (min-width: 768px) {
    font-size: 16px;
    // padding-left: 8px;
    // padding-right: 8px;
  }
  @media (min-width: 1024px) {
    font-size: 16px;
    // padding-left: 12px;
    // padding-right: 12px;
  }
}

.activeTab {
  border-bottom: 2px solid var(--main-icon-color);
  color: var(--text-gray-200-color);
}

.lightActiveTab {
  color: var(--background-dark-color);
}

.lightInactiveTab {
  color: rgba(var(--background-dark-color), 0.6);
}
