@import 'assets/scss/variable.module.scss';

.whiteText {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  font-weight: 100;
  color: var(--text-gray-200-color);
}

.grayText {
  font-family: 'Muli-Light';
  font-size: 16px;
  font-weight: 100;
  color: rgba(var(--text-gray-200-color-rgb), 0.75);
}

.bioText {
  font-family: 'Muli-Light';
  font-size: 16px;
  line-height: 20px;
  color: var(--text-gray-200-color);
}

.greenText {
  color: var(--main-icon-color);
}

.statisticValue {
  font-family: 'Muli-SemiBold';
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
  }
  color: var(--text-gray-200-color);
}

.statisticLabel {
  font-family: 'Muli-Light';
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
  margin-top: 2px;
  @media (min-width: 768px) {
    margin-top: 2px;
    font-size: 16px;
    line-height: 20px;
  }
}

.statisticBorder {
  width: 1px;
  height: 20px;
  background: #707070;
}

.refereshText {
  font-family: 'Muli-Regular';
  font-size: 14px;
  line-height: 18px;
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
  }
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
}

.iconContainer {
  height: 45px;
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
}

.statsConatiner {
  width: 100%;
  height: 58px;
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: var(--background-main-color);
}

.statisticValue {
  font-family: 'Muli-SemiBold';
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
  }
  color: var(--text-gray-200-color);
}

.statisticLabel {
  font-family: 'Muli-Light';
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
  margin-top: 2px;
  @media (min-width: 768px) {
    margin-top: 2px;
    font-size: 16px;
    line-height: 20px;
  }
}


.shareIcon {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
}