@import 'assets/scss/variable.module.scss';

.container {
  // background: rgba(var(--text-gray-200-color-rgb), 0.2);
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
  align-items: center;
  height: 28px;
  font-size: 14px;
  color: var(--text-gray-200-color);
  @media (min-width: 1024px) {
    height: 32px;
    font-size: 16px;
  }
  border: 1px solid rgba(var(--text-gray-200-color-rgb), 0.1);
  border-radius: 16px;
  font-family: 'Muli-SemiBold';
  line-height: 28px;
  cursor: pointer;
}
