@import 'assets/scss/variable.module.scss';

.container {
  position: relative;
  z-index: 10002;
}

.bgView {
  position: fixed;
  inset: 0;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-color: rgba(#1a1a1a, 0.6);
}

.modalView {
  position: relative;
  max-width: 675px;
  width: 100%;
  max-height: calc(100vh - 100px);
  height: 100%;
  background: var(--read-more-bg);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-radius: 20px;

  background-origin: border-box;
  border: 1px solid rgba(var(--background-light-color), 0.1);
  padding: 16px 4px 16px 16px;
  @media (min-width: 1024px) {
    padding: 43px 32px 39px 32px;
  }
}

.close {
  position: absolute;
  top: 18px;
  right: 18px;
  @media (min-width: 1024px) {
    right: 16px;
  }
  cursor: pointer;
  z-index: 20;
}

.iconShadow {
  &:hover {
    @media (min-width: 1024px) {
      filter: drop-shadow(0px 3px 6px var(--brand-01-color));
    }
  }
  cursor: pointer;
}

.profile {
  width: 100%;
  font-family: 'Muli-SemiBold';
  font-size: 20px;
  line-height: 28px;
  color: var(--text-gray-200-color);
  text-align: start;
}

.bio {
  width: 100%;
  @media (max-width: 375px) {
    max-width: 277px;
  }
  margin-top: 15px;
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 24px;
  color: rgba(var(--text-gray-200-color-rgb), 0.9);
  text-align: start;
  word-break: break-word;
}

.category {
  width: 100%;
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 24px;
  color: var(--text-gray-200-color);
  text-align: start;
}

.categoryList {
  width: 100%;
  @media (max-width: 375px) {
    max-width: 277px;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 768px;
  gap: 5px;
}
