@import 'assets/scss/variable.module.scss';

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: var(--main-icon-color);//linear-gradient(to right, var(--primary-btn-bg), var(--primary-btn-bg));
  font-size: 14px;

  @media (min-width: 1024px) {
    height: 46px;
    font-size: 18px;
    line-height: 24px;
    border-radius: 12px;
  }
  @media (min-width: 1536px) {
    height: 53px;
    font-size: 18px;
    line-height: 24px;
    border-radius: 12px;
  }
  border-radius: 8px;
  font-family: 'Muli-SemiBold';
  font-weight: 100;
  letter-spacing: 0;
  color: var(--background-main-color);//var(--gradient-btn-color);
  filter: drop-shadow(0px 3px 6px rgba(var(--brand-01-color), 0.16));
}